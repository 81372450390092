<template>
  <div class="DustRealtimeMonitor">
    <h2>实时监控</h2>
    <el-table :data="listData" style="margin-top: 20px">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="deviceStateStr" label="设备状态">
        <template slot-scope="scope">
          <div class="deviceStateWrap">
            <div :class="'deviceStateIcon' + scope.row.deviceState"></div>
            <span :class="'deviceState' + scope.row.deviceState">{{
              scope.row.deviceStateStr
            }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="deviceSn" label="设备SN码"> </el-table-column>
      <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
      <el-table-column prop="manufacturerName" label="设备厂商">
      </el-table-column>
      <el-table-column align="right" width="190">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleGetLogData(scope.row, 1)" :disabled="onCheckBtnAuthority('DustRealtimeMonitorNow')"
            >当日记录</el-button
          >
          <el-button size="mini" @click="handleGetLogData(scope.row, 2)" :disabled="onCheckBtnAuthority('DustRealtimeMonitorHistory')"
            >历史记录</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="totals"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onGetListData"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 200, 500]"
    >
    </el-pagination>

    <el-dialog
      title="工作数据"
      :visible.sync="showData"
      width="80%"
      :before-close="handleClose"
    >
      <div class="searchWrap" style="padding: 0; margin-bottom: 20px;">
        <el-row v-if="reportingType == 2">
          <el-col :span="6">
            <div class="sb">
              <span>开始日期</span>
              <el-date-picker
                v-model="data_startTime"
                type="date"
                value-format="yyyy-MM-dd"
                class="searchInput"
                size="small"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="sb">
              <span>结束日期</span>
              <el-date-picker
                v-model="data_endTime"
                type="date"
                value-format="yyyy-MM-dd"
                class="searchInput"
                size="small"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="1">
            <el-button type="primary" size="small" @click="onGetLogData"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>
      <el-table :data="data_listData" style="margin-top: 20px">
        <el-table-column
          v-if="data_listKeys.length"
          type="index"
          label="序号"
          width="50"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in data_listKeys"
          :key="index"
          :prop="item"
          :label="data_listValues[index]"
        ></el-table-column>
      </el-table>
      <el-pagination
        layout="total, sizes, prev, pager, next"
        :total="data_totals"
        :page-size="data_pageSize"
        :current-page.sync="data_pageNum"
        @current-change="onGetLogData"
        @size-change="data_handleSizeChange"
        :page-sizes="[10, 50, 100, 200, 500]"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.DustRealtimeMonitor {
  padding: 20px;
  .top {
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
      display: block;
      font-size: 14px;
      color: #ccc;
    }
    .value {
      display: block;
      font-size: 30px;
      color: #000;
      font-weight: 400;
    }
  }
  .bottom {
    padding: 20px;
    background-color: #fff;
  }
  .blankBottom {
    height: 60px;
    background-color: #fff;
  }
  .flexBottom {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    background-color: #fff;
  }

  .deviceStateWrap {
    display: flex;
    vertical-align: middle;
  }
  .deviceState1 {
    display: inline-block;
    color: #f00;
    font-size: 14px;
    line-height: 14px;
  }
  .deviceState0 {
    display: inline-block;
    color: #01b91c;
    font-size: 14px;
    line-height: 14px;
  }
  .deviceStateIcon1 {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: #f00;
    border-radius: 50%;
    line-height: 14px;
    margin-right: 5px;
  }
  .deviceStateIcon0 {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: #01b91c;
    border-radius: 50%;
    line-height: 14px;
    margin-right: 5px;
  }
}
</style>

<script>
export default {
  name: "DustRealtimeMonitor",
  components: {},
  data() {
    return {
      pageBtn: [],
      pageSize: 10,
      pageNum: 1,
      totals: 0,
      listData: [],
      deviceSn: "",
      manufacturerId: "",
      reportingType: "",
      data_pageSize: 10,
      data_pageNum: 1,
      data_totals: 0,
      data_listData: [],
      data_listKeys: [],
      data_listValues: [],
      data_startTime: "",
      data_endTime: "",
      showData: false,
    };
  },
  computed: {},
  mounted() {
    this.onGetPageBtn()
    this.onGetListData();
  },
  methods: {
    // 获取当前页面按钮权限
    onGetPageBtn: function () {
      var that = this;
      var data = {
        id: "1716342581542666242",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/menu/buttons",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.pageBtn = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 检查按钮权限
    onCheckBtnAuthority: function (code) {
      var disable = true;
      if (this.pageBtn.length) {
        this.pageBtn[0].children.forEach((item) => {
          if (item.code == code) {
            disable = false;
          }
        });
      }
      return disable;
    },
    goto: function(path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取主列表数据
    onGetListData: function() {
      var that = this;
      var data = {
        projectId: localStorage.getItem("projectId"),
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/dustDevice/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.listData = res.data.dataList;
            that.totals = res.data.totals;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换分页大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.onGetListData();
    },
    // 切换对话框分页大小
    data_handleSizeChange(val) {
      this.data_pageSize = val;
      this.data_pageNum = 1;
      this.onGetLogData();
    },
    // 关闭
    handleClose: function() {
      this.deviceSn = "";
      this.manufacturerId = "";
      this.reportingType = "";
      this.data_pageSize = 10;
      this.data_pageNum = 1;
      this.data_totals = 0;
      this.daata_listData = [];
      this.data_listKeys = [];
      this.data_listValues = [];
      this.data_startTime = "";
      this.data_endTime = "";
      this.showData = false;
    },
    handleGetLogData: function (row, reportingType) {
      this.deviceSn = row.deviceSn;
      this.manufacturerId = row.manufacturerId;
      this.reportingType = reportingType;
      this.onGetLogData()
    },
    // 获取设备数据
    onGetLogData: function() {
      var that = this;
      var data = {
        deviceSn: this.deviceSn,
        manufacturerId: this.manufacturerId,
        reportingType: this.reportingType,
        startTime: this.data_startTime,
        endTime: this.data_endTime,
        pageNum: this.data_pageNum,
        pageSize: this.data_pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-dust/dustDevice/reportRecord",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.data_listData = res.data.list[0].data;
            that.data_listKeys = Object.keys(res.data.list[0].watchHead[0]);
            that.data_listValues = Object.values(res.data.list[0].watchHead[0]);
            that.data_totals = res.data.totals;
            that.showData = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
